<template>
  <b-card>
    <!-- form -->
    <b-form>
      <validation-observer ref="simpleRules">
        <div class="d-flex">
          <feather-icon icon="UserIcon" size="19" />
          <h4 class="mb-0 ml-50">Invoice Information</h4>
        </div>
        <b-row>
          <!-- Invoice date -->
          <b-col md="6">
            <b-form-group label-for="Invoice Date" label="Invoice Date">
              <flat-pickr
                v-model="invoiceData.invoice_date"
                class="form-control"
                id="invoice Date"
                name="date"
                placeholder="Invoice Date"
              />
            </b-form-group>
          </b-col>
          <!--/ Invoice date -->
          <!-- Invoice From date -->
          <b-col md="6">
            <b-form-group label-for="invoice_from" label="Invoice From Date">
              <flat-pickr
                v-model="invoiceData.invoice_from"
                class="form-control"
                name="date"
                id="invoice_from"
                placeholder="Invoice From Date"
              />
            </b-form-group>
          </b-col>
          <!--/ Invoice From date -->
          <!-- Invoice To date -->
          <b-col md="6">
            <b-form-group label-for="invoice_to" label="Invoice To Date">
              <flat-pickr
                v-model="invoiceData.invoice_to"
                class="form-control"
                id="invoice_to"
                name="date"
                placeholder="Invoice To Date"
              />
            </b-form-group>
          </b-col>
          <!--/ Invoice To date -->
          <!-- Invoice last_payment_date date -->
          <b-col md="6">
            <b-form-group
              label-for="last_payment_date"
              label="Invoice Last Payment Date"
            >
              <flat-pickr
                v-model="invoiceData.last_payment_date"
                class="form-control"
                name="date"
                placeholder="Invoice Last Payment Date"
              />
            </b-form-group>
          </b-col>
          <!--/ Invoice last_payment_date date -->
          <!-- Country -->
          <b-col md="6">
            <b-form-checkbox
              id="accountSwitch1"
              :checked="invoiceData.is_fcm_enable"
              name="check-button"
              switch
              inline
            >
              <span>FCM Notifications Enable/Disable</span>
            </b-form-checkbox>
          </b-col>
          <!--/ Country -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click.prevent="validationForm"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              class="mt-1"
              variant="outline-secondary"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import {checkRes} from "@core/services/helper";
import  Mixin  from "@core/services/mixin"; 

export default {
        mixins:[
        Mixin
    ],
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    invoiceData: Object,
  },
  data() {
    return {
      invoiceDataFrom:true,
      ispId:this.$route.query.isp_id
    };
  },
  methods: {
    resetForm() {
      this.invoiceData = this.invoiceData;
    },
     validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                   useJwt.updateIspProfileInfo({
                       isp_id:this.ispId,
                       invoiceData:this.invoiceDataFrom,
                       invoice_date: this.invoiceData.invoice_date ?? null,
                       invoice_from: this.invoiceData.invoice_from ?? null,
                       invoice_to: this.invoiceData.invoice_to,
                       last_payment_date: this.invoiceData.last_payment_date,
                       is_fcm_enable: this.invoiceData.is_fcm_enable, 
                    }).then(response => {
                       if (checkRes(response.status) && response.data.data) {
                           this.$router.push({ name: 'account-setting' })
                            .catch(error => {
                                this.toastMessage('success','Profile Info',response)
                            })
                       }else {
                           this.$router.push({ name: 'account-setting' }).then(() => {
                               this.toastMessage('warning','Profile Info',response)
                           }).catch(error => {
                               this.toastMessage('danger','Profile Info',response)
                           })
                       }
                    })
                }
            })
        }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
