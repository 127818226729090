import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    methods: {
        toastMessage(messegetype, msgtitle, response) {
            if (response.data) {
                for (const message of response.data.messages) {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: msgtitle,
                            icon: 'CoffeeIcon',
                            variant: (messegetype) ? messegetype : 'success',
                            text: (message) ? message : "Something Wrong!!!",
                        },
                    })
                }
            }

        }
    }
}
