<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        v-if="options"
        :general-data="options"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- invoice -->
     <b-tab> 
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Invoice Setting</span>
      </template>

      <invoice-setting-information
        v-if="options"
        :invoice-data="options"
      />
    </b-tab>
     <!-- Settlement Setting -->
     <b-tab> 
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Settlement Setting</span>
      </template>

      <settlement-setting-information
        v-if="options"
        :settlement-data="options"
      />
    </b-tab> 
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import InvoiceSettingInformation from './InvoiceSettingInformation.vue'
import SettlementSettingInformation from './SettlementSettingInformation.vue' 
import {ISP_PROFILE_INFO} from "@core/services/api";

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    InvoiceSettingInformation, 
    SettlementSettingInformation
  },
  props: { 
  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {
    console.log(this.$route.query.isp_id);
    let ispId = this.$route.query.isp_id;
    this.$http.get(process.env.VUE_APP_BASEURL+  ISP_PROFILE_INFO+'?isp_id='+ispId).then(res => { this.options = res.data.data })
  }, 
}
</script>
