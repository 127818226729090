<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <validation-observer ref="simpleRules">
        <div class="d-flex">
          <feather-icon icon="UserIcon" size="19" />
          <h4 class="mb-0 ml-50">Personal Information</h4> 
        </div>
        <b-row>
          <!-- Field: Full Name -->
          <b-col sm="6" cols="12" md="4">
            <b-form-group label="Name" label-for="full-name">
              <b-form-input
                id="full-name"
                v-model="generalData.isp_account_name"
                placeholder="Enter Person Name"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Email -->
          <b-col sm="6" cols="12" md="4">
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="generalData.isp_account_email"
                type="email"
                placeholder="Enter Person Email"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Mobile -->
          <b-col sm="6" cols="12" md="6" lg="4">
            <b-form-group label="Mobile" label-for="mobile">
              <b-form-input
                id="mobile"
                v-model="generalData.isp_account_mobile"
                readonly="readonly"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-2">
            <feather-icon icon="BriefcaseIcon" size="19" />
            <h4 class="mb-0 ml-50">
                Company Information
            </h4>
        </div>
        <b-row class="mt-1">
           <!-- Field: ISP Type -->
            <b-col sm="6" cols="12" md="6" lg="4" >
                <b-form-group
                    label="ISP Licence Type"
                    label-for="isp_licence_type">
                    <validation-provider
                        #default="{ errors }"
                        name="ISP Licence Type">
                        <v-select
                            v-model="generalData.isp_licence_type"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="isp_licence_type_list"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="user-role"
                            placeholder="Select ISP Licence Type"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <!-- Field: Company name -->
            <b-col sm="6" cols="12" md="6" lg="4">
                <b-form-group label="Company Name" label-for="company_name" >
                    <b-form-input id="company_name" v-model="generalData.company_name" placeholder="Enter Company Name" />
                </b-form-group>
            </b-col>
            <!-- Field: Company Mobile -->
            <b-col sm="6" cols="12" md="6" lg="4" >
                <b-form-group label="Company Mobile" label-for="company_phone" >
                    <b-form-input id="company_phone" v-model="generalData.company_phone" placeholder="Enter Company Mobile Number" />
                </b-form-group>
            </b-col>
            <!-- Field: Company Email -->
            <b-col sm="6" cols="12" md="6" lg="4" >
                <b-form-group label="Company Email" label-for="company_email" >
                    <b-form-input id="company_email" v-model="generalData.company_email" placeholder="Enter Company Email Address" />
                </b-form-group>
            </b-col>

            <!-- Field: Website -->
            <b-col sm="6" cols="12" md="6" lg="4" >
                <b-form-group label="Website" label-for="website" >
                    <b-form-input id="website" v-model="generalData.web_url" />
                </b-form-group>
            </b-col>
        </b-row>
        <div class="d-flex mt-2">
            <feather-icon icon="BriefcaseIcon" size="19" />
            <h4 class="mb-0 ml-50">
                Hot Line Number Information
            </h4>
        </div>
        <b-row class="mt-1">
           <!-- Field: Hot Line number one -->
            <b-col sm="6" cols="12" md="6" lg="4" >
                <b-form-group label="Hot Line Number 1" label-for="hot_line_one" >
                    <b-form-input id="hot_line_one" v-model="generalData.hot_line_one" placeholder="Enter Hot Line Number 1" />
                </b-form-group>
            </b-col>
            <!-- Field: Hot Line number two -->
            <b-col sm="6" cols="12" md="6" lg="4" >
                <b-form-group label="Hot Line Number 2" label-for="hot_line_two" >
                    <b-form-input id="hot_line_two" v-model="generalData.hot_line_two" placeholder="Enter Hot Line Number 2" />
                </b-form-group>
            </b-col>
            <!-- Field: Hot Line number three -->
            <b-col sm="6" cols="12" md="6" lg="4" >
                <b-form-group label="Hot Line Number 3" label-for="hot_line_three" >
                    <b-form-input id="hot_line_three" v-model="generalData.hot_line_three" placeholder="Enter Hot Line Number 3" />
                </b-form-group>
            </b-col>
            <!-- Field: MikroBilling Calling ID -->
            <b-col sm="6" cols="15" md="6" lg="4" >
                <b-form-group label="MikroBilling Calling ID" label-for="cx_call_id" >
                    <b-form-input id="cx_call_id" v-model="generalData.cx_call_id" placeholder="Enter MikroBilling Calling ID" />
                </b-form-group>
            </b-col>
            <!-- Field: Support Time -->
            <b-col sm="6" cols="12" md="6" lg="4" >
                <b-form-group label="Support Time" label-for="support_time" >
                    <b-form-input id="support_time" v-model="generalData.support_time" placeholder="Enter Support Time" />
                </b-form-group>
            </b-col>
            
        </b-row>
        <div class="d-flex mt-2">
        <feather-icon icon="MapPinIcon" size="19" />
        <h4 class="mb-0 ml-50">
            Registered Company Address
        </h4>
      </div>
       <b-row class="mt-1">
          <b-col cols="12" md="6" lg="4" >
                  <b-form-group
                      label="Division"
                      label-for="division_id">
                      <validation-provider
                          #default="{ errors }"
                          name="Division Name" >
                          <v-select
                              v-on:input="getAllDistrictData($event)"
                              v-model="generalData.division_id"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="division_list"
                              :reduce="val => val.value"
                              :clearable="false"
                              input-id="user-role"
                              placeholder="Select Division"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
          </b-col>
          <!-- Form: Personal Info Form -->
          <b-col cols="12" md="6" lg="4" >
                  <b-form-group
                      label="District"
                      label-for="division_id">
                      <validation-provider
                          #default="{ errors }"
                          name="Division Name" >
                          <v-select
                              v-on:input="getAllUpazilaData($event)"
                              v-model="generalData.district_id"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="district_list"
                              :reduce="val => val.value"
                              :clearable="false"
                              input-id="user-role"
                              placeholder="Select District"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
          </b-col>
          <!-- Form: Personal Info Form -->
          <b-col cols="12" md="6" lg="4" >
              <b-form-group
                  label="Upazila"
                  label-for="division_id">
                  <validation-provider
                      #default="{ errors }"
                      name="Division Name" >
                      <v-select
                          v-model="generalData.upazila_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="upazila_list"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="user-role"
                          placeholder="Select Upazila"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
          </b-col>
        <!-- Field: Address Company -->
        <b-col cols="12" md="6" lg="4" >
          <b-form-group
            label="Company Address" label-for="company_address" >
            <b-form-input id="company_address" v-model="generalData.company_address" />
          </b-form-group>
        </b-col>
      </b-row>
        <b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click.prevent="validationForm"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from 'vue-select';
import {DISTRICT_LIST, DIVISION_LIST, UPAZILA_LIST,ISP_LICENCE_LIST} from "@core/services/api";
import useJwt from "@/auth/jwt/useJwt"; 
import  Mixin  from "@core/services/mixin"; 
import {checkRes} from "@core/services/helper";

export default {
      mixins:[
        Mixin
    ],
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData:Object,
  },
  data() {
    return { 
      isp_licence_type_list:[],
      division_list:[],
      district_list:[],
      upazila_list:[],
      ispId:this.$route.query.isp_id
    };
  },
  methods: {
    resetForm() {
      this.generalData = this.generalData;
    },
    getAllIspLicenceData() {
      this.$http.get(process.env.VUE_APP_BASEURL+  ISP_LICENCE_LIST)
          .then(res => {
              this.isp_licence_type_list = res.data.data
          }).catch(error => {
          if (error.response.status === 404) {
              return false
          }
      })
  },
  getAllDivisionData() {
            this.$http.get(process.env.VUE_APP_BASEURL+  DIVISION_LIST)
                .then(res => {
                    this.division_list = res.data.data
                }).catch(error => {
                    return false
            })
        },
        getAllDistrictData(event) {
            if (event){
                this.generalData.district_id = null
            }
            this.$http.get(process.env.VUE_APP_BASEURL +  DISTRICT_LIST + (event ? event : this.generalData.division_id))
                .then(res => {
                    this.district_list = res.data.data
                }).catch(error => {
                return false
            })
        }
        ,
        getAllUpazilaData(event) {
            if (event){
                this.generalData.upazila_id = null
            }
            this.$http.get(process.env.VUE_APP_BASEURL+  UPAZILA_LIST + (event ? event : this.generalData.district_id))
                .then(res => {
                    this.upazila_list = res.data.data
                }).catch(error => {
                    return false
            })
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                   useJwt.updateIspProfileInfo({
                       isp_id:this.ispId,
                       name: this.generalData.isp_account_name ?? null,
                       email: this.generalData.isp_account_email ?? null,
                       division_id: this.generalData.division_id,
                       district_id: this.generalData.district_id,
                       upazila_id: this.generalData.upazila_id,
                       company_name: this.generalData.company_name,
                       company_email: this.generalData.company_email,
                       company_phone: this.generalData.company_phone,
                       hot_line_one: this.generalData.hot_line_one,
                       hot_line_two: this.generalData.hot_line_two,
                       hot_line_three: this.generalData.hot_line_three,
                       cx_call_id: this.generalData.cx_call_id,
                       support_time: this.generalData.support_time,
                       company_address: this.generalData.company_address,
                       web_url: this.generalData.web_url,
                       isp_licence_type: this.generalData.isp_licence_type,
                    }).then(response => {
                       if (checkRes(response.status) && response.data.data) {
                           this.$router.push({ name: 'account-setting' })
                            .catch(error => {
                                this.toastMessage('success','Profile Info',response)
                            })
                       }else {
                           this.$router.push({ name: 'account-setting' }).then(() => {
                               this.toastMessage('warning','Profile Info',response)
                           }).catch(error => {
                               this.toastMessage('danger','Profile Info',response)
                           })
                       }
                    })
                }
            })
        },
  },
  mounted() {
    this.getAllIspLicenceData(); 
    this.getAllDivisionData();
    this.getAllDistrictData();
    this.getAllUpazilaData();
  }
};
</script>
